@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

body{
  margin: 0;
  background: #f5f5f5;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.dot-bg{
  background: url('../assets/achievementbg-copy.png');
  background-blend-mode: multiply;
  background-size: contain;
  background-repeat: repeat;
}

header{
  background-color: #b3d0e3;
  padding: 10px;
  height: 90px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.theme-gradient{
  background: linear-gradient(to right, #0a88dc 45%, #8ac546 55%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.menu-icon{
  font-size: 40px;
  font-weight: bold;
  padding: 0 5px;
  color: #004470;
  border-radius: 4px;
}

/* Achievement Container */
.achievement-container{
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Contact social icons */

.contact-social-icon-container i:hover{
  cursor: pointer;
  transform: scale(120%);
}

.bi-instagram{
  color: #E5028B;
}

.bi-envelope-at{
  color: #e0e0e0;
}

.bi-twitter{
  color: #00ace8;
}





/* CSS for Mobile Screens */
@media screen and (max-width: 500px){

  /* Header */
  header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

   /* Header logo */
  .logo{
    height: 50px;
  }

  /* Desktop Menu items Container */
  .lg-menu-container{
    display: none;
  }

  /* Home Container */
  .home-container{
    text-align: center;
    padding: 50px 0;
    background: rgb(61, 61, 61) url('../assets/medium-shot-girl-reading-book.jpg');
    background-size: cover;
    background-position-y: center;
    background-blend-mode: multiply;
  }

 /* Parent container of a slide */
  .About-container{
    font-size: 16px;
    /* background: linear-gradient(to right, #0071bf 0%, #8dc643 100%); */

  }

  /* Small screen About info container */
  .about-info-container{
    padding: 15px;
    text-align: justify;
  }

  /* Tablet & Desktop carousel */
  #AboutCarousel{
    display: none;
  }

  /* Mobile sm carousel */
  #AboutCarousel-sm{
    display: block;
  }

  /* Responsive About carousel */
  .about-slide{
    height: 430px;
    width: 350px;
  }

  /* About cards */
  /* .about-card-right, .about-card-left{

    
  } */

  /* About right content container */
  .about-content-right{
    width: 100%;
    height: fit-content;
    margin: 0 10px;
    display: flex;
    align-items: center;
  }

  /* Inner Container of Right About container */
  .inner-about-content-right{
    background-color: #b3d0e3;
    color: #202020;
    height: 450px;
    width: 100%;
    box-sizing: border-box;
    text-align: justify;
    box-shadow: -5px 5px 10px #131313;
    padding: 20px;
    border-radius: 12px;
  }


  /* Right About card image */
  .about-img-right{
    display: none;
  }

  /* Left About-container */
  .about-content-left{
    width: 100%;
    height: fit-content;
    margin: 0 10px;
    display: flex;
    align-items: center;
  }

  /* Innner Container of left About container */
  .inner-about-content-left{
    background-color: #b3d0e3;
    color: #202020;
    height: 450px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    text-align: justify;
    box-shadow: 5px 5px 10px #131313;
    border-radius: 8px;
  }


  /* Left About card image */
  .about-img-left{
    display: none;
  }

      /* Achievement cards */
    .achievement-card{
      margin: 0 10px;
      padding: 15px;
    }
  
    /* Achievement card count */
    .achievement-card-count{
      font-size: 45px;
      font-weight: 600;
      color: #000000;
      margin: 2px 0;
    }
  
    /* Achievement cards p*/
    .achievement-card-title{
      color: rgb(107, 107, 107);
      font-weight: bolder;
      font-size: 20px;
    }

    /* Process flowchart diagram */
    .oranization-diagram{
      display: none;
    }


    /* Service container Background */
  .service-component-container-bg{
    /* background: url('../assets/blur\ bgg.png'); */
    background: linear-gradient(to bottom, #c2e4fa, #ddf8be);
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

  /* Service container Body */
  .service-component-container-body{
    backdrop-filter: blur(100px);
    padding: 20px 10px;
  }

  /* Service headings and contents container */
  .service-component-container {
    display: none;
  }


  /* Start of Contact CSS */
  /* Contact container */
  .enquiry-container{
    background: url('../assets/contactbg.png');
  }

  /* Contact title's Description */
  .contact-desc{
    font-size: 16px;
  }

  /* Container of Form and Contact cover image */
  .contact-form-img-container{
    width: 100%;
  }

  /* Contact images container */
  .contact-img-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background: #424246 url('../assets/contactcover.jpg');
    background-blend-mode: multiply;
    background-size: cover;
    background-position: bottom;
    color: #fff;
  }

  /* Contact social image container */
  .contact-social-icon-container i{
    font-size: 52px;
    margin: 20px;
  }


  /* Form container */
  .form-container{
    width: 100%;
    height: fit-content;
  }

  /* Form */
  .contact-form{
    background-color: #e4f2fd;
    padding: 30px 20px;
    width: 95%;
    border-radius: 8px;
    margin: 10px auto;
    height: fit-content;
  }

  /* Form's Description*/
  .form-desc{
    font-size: 18px;
    color: #004470;
    font-weight: 600;
  }
  /* End of Contact CSS */


  /* Start of Gallery */
  /* Gallery slide's container */
  .gallery-slide-container{
    width: 100%;
    padding: 10px;
  }

  /* Gallery's First slide */
  .gallery-slide-one{
    width: 95%;
  }

  /* common Galley slide */
  .gallery-slide{
    /* background: linear-gradient(to right, #0a88dc -50%, #8ac546 150%); */
    background: #e4f2fd;
    color: #004470;
    width: 95%;
    padding: 10px;
    height: fit-content;
    margin: 10px auto;
  }

  /* Gallery's First slide top block */
  .slide-one-top-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 160px;
    width: 100%;
  }
  .slide-one-bottom-block{
    height: fit-content;
    width: 100%;
  }

  /* Slide images */
  .slide-img{
    filter: brightness(95%);
  }

  /* Slide Description */
  .slide-desc{
    font-weight: 600;
    font-family: monospace;
    height: 140px;
  }

  /* First Slide A1 & A2 img */
  .slide-a1, .slide-a2{
    width: 49.5%;
    height: 150px;
    object-fit: cover;
  }

  /* First Slide A3 img */
  .slide-a3{
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  /* Gallery's Second slide */
  .gallery-slide-two{
    width: 95%;
  }

  /* Gallery Slide image container */
  .gallery-slide-img-container{
    height: 320px !important;
    width: 100%;
  }

  /* Second slide img */
  .slide-b1{
    width: 100%;
    height: 310px;
    object-fit: cover;
  }

  /* Gallery's Third slide */
  .gallery-slide-three{
    width: 95%;
  }

  /* Second slide img */
  .slide-c1, .slide-c2{
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 100% 35%;
    margin: 2px;
  }
  /* End of Gallery */




  /* Courses Slides */
  .course-slide{
    border: 1px solid #004470;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px auto;
    width: 95%;
    height: 680px;
  }

  /* Title of Courses */
  .course-title{
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 4px;
    color: #262338;
    margin: 10px 0 10px 20px;
    text-align: center;
  }

  /* Course list And More... style */
  .courses-and-more{
    color: #004470;
  }

  /* Container of Course list */
  .course-list-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  /* Partition of Course list */
  .course-list-partition{
    width: 300px;
    margin: 0 30px;
    border-left: 4px solid #6ca6cd;
    padding-left: 20px;
    height: fit-content;
  }

  /* Start of Testimonial */
  /* Testimonial Container */
  .testimonial-slide{
    margin: 10px auto;
    width: 95%;
    padding: 10px;
    border: 5px solid #004470;
    border-radius: 12px;
    background-color: #e4f2fd;
    min-height: 800px;
  }

  /* Testimonial avatar container */
  .testimonial-dp-container{
    width: 100%;
  }


  /* Testimonial bottom container */
  .testimonial-bottom-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .testimonial-star{
    font-size: 24px;
    margin: 10px;
  }

  /* Testimonial DP */
  .testimonial-dp{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 30px;
  }

  /* Testimonial Authors social icons */
  .testimonial-social-icons{
    font-size: 22px;
    margin: 10px;
  }

  /* Testimonial Description */
  .testimonial-desc{
    text-align: justify;
    height: 520px;
  }

  .testimonial-author{
    color: #202020;
  }

  .testimonial-designation{
    text-align: end;
  }
  /* End of Testimonial */

  
  /* Start of Footer */

  .footer-address-container{
    width: fit-content;
  }
  /* Footer top part */
  .footer-top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .quick-link-container{
    margin: 10px;
    text-align: center;
  }

  /* Quick link title */
  .quick-link-title{
    margin: 5px 0;
  }

  /* Quick links */
  .quick-link{
    text-decoration: none;
    color: #a9ddff;
    display: block;
    padding: 1px 16px;
  }
  /* End of Footer */

  /* Start of Backtop top Container */
  .Backtotop-container{
    margin-bottom: 20px;
    margin-right: 20px;
  }
  /* End of Backtop top Container */

}








/* CSS for Tablet Screens */
@media screen and (min-width: 500px) and (max-width: 1000px){

  /* Header */
  header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Header logo */
  .logo{
    height: 70px;
  }

  /* Desktop Menu items Container */
  .lg-menu-container{
    display: none;
  }

  /* Home Container */
  .home-container{
    text-align: center;
    padding: 50px 20px;
    background: rgb(61, 61, 61) url('../assets/medium-shot-girl-reading-book.jpg');
    background-size: cover;
    background-position-y: center;
    background-blend-mode: multiply;
  }

  .About-container{
    font-size: 13px;
  }

  /* Responsive About carousel */
  .about-slide{
    height: 500px;
    width: 450px;
  }

  /* Mobile sm carousel */
  #AboutCarousel-sm{
    display: none;
  }

  /* Small screen About info container */
  .about-info-container{
    display: none;
  }

  /* About content right side container */
  .about-content-right{
    width: 100%;
    height: 480px;
    margin: -20px 0 0 0;
    background-color: #b3d0e3;
  }

  /* Innner Container of About right container */
  .inner-about-content-right{
    background-color: #b3d0e3;
    color: #202020;
    height: 100%;
    width: 90%;
    box-sizing: border-box;
    transform: skewX(-10deg);
    margin-left: -40px;
    padding: 10px 0 0 40px;
    text-align: justify;
    box-shadow: -5px 0 5px #131313;
  }


  /* About card image rigt*/
  .about-img-right{
    height: 400px;
    width: 30%;
    object-fit: cover;
  }

  .inner-about-content-right p, .inner-about-content-right h2{
    transform: skewX(10deg)
  }
  .inner-about-content-left p, 
  .inner-about-content-left h2{
    transform: skewX(10deg)
  }

  /* Left About-container */
  .about-content-left{
    width: 100%;
    height: 480px;
    margin: -20px 0 0 0;
    background-color: #b3d0e3;
  }

  /* Innner Container of left About container */
  .inner-about-content-left{
    background-color: #b3d0e3;
    color: #202020;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transform: skewX(-10deg);
    margin-left: 50px;
    padding: 10px 60px 0 0;
    text-align: justify;
    box-shadow: 5px 0 5px #131313;
  }


  /* Left About card image */
  .about-img-left{
    height: 400px;
    width: 30%;
    object-fit: cover;
  }

    /* Achievement cards */
    .achievement-card{
      margin: 20px 30px;
      padding: 20px;
    }
  
    /* Achievement card count */
    .achievement-card-count{
      font-size: 45px;
      font-weight: 600;
      color: #202020;
      margin: 2px 0;
    }
  
    /* Achievement cards p*/
    .achievement-card-title{
      font-weight: bolder;
      font-size: 18px;
    }
  
    /* Process flowchart diagram */
    .process-diagram{
      height: 450px;
    }
    .oranization-diagram{
      display: none;
    }


    /* Service container Background */
  .service-component-container-bg{
    /* background: url('../assets/blur\ bgg.png'); */
    background: linear-gradient(to bottom, #c2e4fa, #ddf8be);
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

  /* Service container Body */
  .service-component-container-body{
    backdrop-filter: blur(50px);
    padding: 20px 10px;
  }

  /* Service headings and contents container */
  .service-component-container {
    display: none;
  }

  /* Service headings container */
  .headings-container {
    width: 300px; /* Adjust as needed */
    overflow-y: auto;
    padding: 10px;
    display: none;
  }

  /* Service Headings */
  .heading {
    cursor: pointer;
    padding: 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;
    border-bottom: 2px solid transparent;
    color: #424246;
  }
  .heading:hover {
    border-bottom: 2px dotted #004470;
  }
  .heading.active {
    font-weight: bold;
  }

  /* Service contents container */
  .contents-container {
    padding: 20px;
    /* overflow-y: scroll;
    height: 300px; */
    width: 100%;
  }

  /* Service sub-headings */
  .content-heading{
    font-size: 18px;
    font-weight: bold;
    color: #004470;
    text-align: center;
  }

  /* Service descriptions */
  .content-desc{
    text-align: justify;
  }

  /* Service Contents */
  .content {
    margin-top: 30px;
  }



  /* Start of Contact CSS */
  /* Contact container */
  .enquiry-container{
    background: url('../assets/contactbg.png');
    padding: 10px;
  }

  /* Contact title's Description */
  .contact-desc{
    font-size: 16px;
  }

  /* Container of Form and Contact cover image */
  .contact-form-img-container{
    display: flex;
    justify-content: center;
    height: fit-content;
    margin: 10px;
  }

  /* Contact images container */
  .contact-img-container{
    min-width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1070px;
    background: #424246 url('../assets/contactcover.jpg');
    background-blend-mode: multiply;
    background-size: cover;
    color: #fff;
  }

  /* Contact social image container */
  .contact-social-icon-container i{
    display: block;
    font-size: 52px;
    margin: 20px;
  }


  /* Form container */
  /* .form-container{
    
  } */

  /* Form */
  .contact-form{
    background-color: #e4f2fd;
    padding: 30px 20px;
    min-width: 350px;
    border-radius: 8px;
    margin: 0 auto;
  }

  /* Form's Description*/
  .form-desc{
    font-size: 18px;
    color: #004470;
    font-weight: 600;
  }
  /* End of Contact CSS */

  /* Start of Gallery */
  /* Gallery slide's container */
  .gallery-slide-container{
    width: 100%;
    padding: 20px 50px;
  }

  /* Gallery's First slide */
  .gallery-slide-one{
    width: 95%;
  }

  /* Common gallery slide parent item */
  .gallery-slide-item{
    height: 530px;
    width: 450px;
  }

  /* common Galley slide */
  .gallery-slide{
    /* background: linear-gradient(to right, #0a88dc -50%, #8ac546 150%); */
    background: #e4f2fd;
    color: #004470;
    width: 95%;
    padding: 10px;
    height: fit-content;
    margin: 10px auto;
  }

  /* Gallery's First slide top block */
  .slide-one-top-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .slide-one-bottom-block{
    height: fit-content;
    width: 100%;
  }

  /* Slide images */
  .slide-img{
    filter: brightness(95%);
  }

  /* Slide Description */
  .slide-desc{
    font-weight: 600;
    font-family: monospace;
    height: 110px;
  }

  /* First Slide A1 & A2 img */
  .slide-a1, .slide-a2{
    width: 49.5%;
    height: 170px;
    object-fit: cover;
  }

  /* First Slide A3 img */
  .slide-a3{
    width: 100%;
    height: 170px;
    object-fit: cover;
    margin: 5px 0;
  }

  /* Gallery's Second slide */
  .gallery-slide-two{
    width: 95%;
  }

  /* Slide two image container */
  .gallery-slide-img-container{
    display: block;
    height: 350px;
    width: 100%;
  }

  /* Second slide img */
  .slide-b1{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Gallery's Third slide */
  .gallery-slide-three{
    width: 95%;
  }

  /* Second slide img */
  .slide-c1{
    width: 49.5%;
    height: 100%;
    object-fit: cover;
    object-position: 15% 100%;
  }
  .slide-c2{
    width: 49.5%;
    height: 100%;
    object-fit: cover;
    object-position: 70% 100%;
  }
  /* End of Gallery */

  /* Courses Slides */
  .course-slide{
    border: 1px solid #004470;
    border-radius: 30px;
    padding: 10px 20px;
    margin: 10px auto;
    width: 80%;
    height: 680px;
  }

  /* Title of Courses */
  .course-title{
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 4px;
    color: #262338;
    margin: 10px 0 10px 20px;
    text-align: center;
  }

  /* Course list And More... style */
  .courses-and-more{
    color: #004470;
  }

  /* Container of Course list */
  .course-list-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  /* Partition of Course list */
  .course-list-partition{
    width: 300px;
    margin: 0 30px;
    border-left: 4px solid #6ca6cd;
    padding-left: 30px;
    height: fit-content;
  }

  /* Start of Testimonial */
  /* Testimonial Container */
  .testimonial-slide{
    margin: 10px auto;
    width: 90%;
    padding: 15px;
    border: 5px solid #004470;
    border-radius: 12px;
    background-color: #e4f2fd;
    min-height: 600px;
  }

  /* Testimonial avatar container */
  .testimonial-dp-container{
    width: 100%;
  }

  /* Testimonial bottom container */
  .testimonial-bottom-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .testimonial-star{
    font-size: 24px;
    margin: 10px;
  }

  /* Testimonial DP */
  .testimonial-dp{
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 30px;
  }

  /* Testimonial Authors social icons */
  .testimonial-social-icons{
    font-size: 22px;
    margin: 10px;
  }

  /* Testimonial Description */
  .testimonial-desc{
    text-align: justify;
    height: 400px;
    overflow: scroll;
  }

  .testimonial-author{
    color: #202020;
  }

  .testimonial-designation{
    text-align: end;
  }
  /* End of Testimonial */

  /* Start of Footer */
  /* Footer top part */
  .footer-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quick-link-container{
    margin: 20px;
  }

  /* Quick links */
  .quick-link{
    text-decoration: none;
    color: #a9ddff;
    display: block;
    padding: 0 16px;
  }
  /* End of Footer */

  /* Start of Backtop top Container */
  .Backtotop-container{
    margin-bottom: 15px;
    margin-right: 15px;
  }
  /* End of Backtop top Container */

}








/* CSS for Desktop Screens */
@media screen and (min-width: 1000px){

  /* Header */
  header{
    display: flex;
    text-align: center;
  }

  /* Header logo */
  .logo{
    width: 100px;
  }

  /* Desktop Menu items Container */
  .lg-menu-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0 0 0 -100px;
  }

  /* Desktop Menu items */
  .lg-menu{
    text-decoration: none;
    color: #004470;
    font-size: 16px;
    font-weight: 600;
    margin: 10px;
    cursor: pointer;
    padding: 5px;
  }

  /* Menu icon */
  .dropdown{
    display: none;
  }

  /* Home Container */
  .home-container{
    text-align: center;
    padding: 105px 50px;
    background: rgb(61, 61, 61) url('../assets/medium-shot-girl-reading-book.jpg');
    background-size: cover;
    background-position-y: center;
    background-blend-mode: multiply;
  }

  /* Mobile sm carousel */
  #AboutCarousel-sm{
    display: none;
  }

  /* Responsive About carousel */
  .about-slide{
    height: 450px;
    width: 1000px;
  }

  /* Small screen About info container */
  .about-info-container{
    display: none;
  }

  /* About RIght content Container */
  .about-content-right{
    width: 100%;
    height: 440px;
    margin: -20px 0 0 0;
    background-color: #b3d0e3;
  }

  /* Innner Container of Right About container */
  .inner-about-content-right{
    background-color: #b3d0e3;
    color: #202020;
    height: 100%;
    width: 90%;
    box-sizing: border-box;
    transform: skewX(-10deg);
    margin-left: -40px;
    padding: 30px 0 5px 50px;
    text-align: justify;
    box-shadow: -5px 0 4px #131313;
  }
  .inner-about-content-right p, .inner-about-content-right h3, .inner-about-content-right ol, .inner-about-content-right ul{
    transform: skewX(10deg)
  }
  .inner-about-content-left p, 
  .inner-about-content-left h3, .inner-about-content-left ol, .inner-about-content-left ul{
    transform: skewX(10deg)
  }


  /* Right About card image */
  .about-img-right{
    height: 400px;
    width: 40%;
    object-fit: cover;
  }

  /* Left About-container */
  .about-content-left{
    width: 100%;
    height: 440px;
    margin: -20px 0 0 0;
    background-color: #b3d0e3;
  }

  /* Innner Container of left About container */
  .inner-about-content-left{
    background-color: #b3d0e3;
    color: #202020;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transform: skewX(-10deg);
    margin-left: 50px;
    padding: 30px 60px 0 0;
    text-align: justify;
    box-shadow: 5px 0 5px #131313;
  }


  /* Left About card image */
  .about-img-left{
    height: 400px;
    width: 40%;
    object-fit: cover;
  }

  /* Achievement cards */
  .achievement-card{
    margin: 10px 20px;
    border-right: 1px solid #c4c4c4;
    /* border: 1px solid #c4c4c4; */
    padding: 0 20px 0 0;
  }

  /* Achievement card count */
  .achievement-card-count{
    font-size: 45px;
    font-weight: 600;
    color: #000000;
    margin: 2px 0;
  }

  /* Achievement cards p*/
  .achievement-card-title{
    color: rgb(107, 107, 107);
    font-weight: bolder;
    margin: 0;
  }

  /* Process flowchart diagram */
  .process-diagram{
    display: none;
  }
  .oranization-diagram{
    height: 300px;
  }


  /* Service container Background */
  .service-component-container-bg{
    background: url('../assets/blur\ bgg.png');
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

  /* Service container Body */
  .service-component-container-body{
    backdrop-filter: blur(50px);
    padding: 20px 10px;
  }

  /* Service headings and contents container */
  .service-component-container {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  /* Service headings container */
  .headings-container {
    width: 300px; /* Adjust as needed */
    overflow-y: auto;
    padding: 10px;
  }

  /* Smal screen accordion */
  #serviceAccordion{
    display: none;
  }

  /* Service Headings */
  .heading {
    cursor: pointer;
    padding: 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;
    border-bottom: 2px solid transparent;
    color: #424246;
  }
  .heading:hover {
    border-bottom: 2px dotted #004470;
  }
  .heading.active {
    font-weight: bold;
  }

  /* Service contents container */
  .contents-container {
    padding: 10px;
    overflow-y: scroll;
    height: 300px;
    width: 100%;
  }

  /* Service sub-headings */
  .content-heading{
    font-size: 18px;
    font-weight: bold;
    color: #004470;
  }

  /* Service Contents */
  .content {
    margin-top: 0;
  }

  /* Start of Contact CSS */
  /* Contact container */
  .enquiry-container{
    background: url('../assets/contactbg.png');
  }

  /* Contact title's Description */
  .contact-desc{
    font-size: 16px;
  }

  /* Container of Form and Contact cover image */
  .contact-form-img-container{
    display: flex;
    justify-content: center;
    height: fit-content;
    margin: 10px;
  }

  /* Contact images container */
  .contact-img-container{
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1070px;
    background: #424246 url('../assets/contactcover.jpg');
    background-blend-mode: multiply;
    background-size: cover;
    color: #fff;
  }

  /* Contact social image container */
  .contact-social-icon-container i{
    display: block;
    font-size: 72px;
    margin: 20px;
  }





  /* Form container */
  .form-container{
    /* margin: 30px; */
  }

  /* Form */
  .contact-form{
    background-color: #e4f2fd;
    padding: 30px 20px;
    width: 500px;
    height: 1070px;
  }

  /* Form's Description*/
  .form-desc{
    font-size: 18px;
    color: #004470;
    font-weight: 600;
  }
  /* End of Contact CSS */

  /* Start of Gallery */
  /* Gallery slide's container */
  .gallery-slide-container{
    width: 100%;
    padding: 20px 50px;
  }

  /* Gallery's First slide */
  .gallery-slide-one{
    width: 95%;
  }

  /* Common gallery slide parent item */
  .gallery-slide-item{
    height: 550px;
  }

  /* common Galley slide */
  .gallery-slide{
    background: #e4f2fd;
    color: #004470;
    padding: 10px;
    height: 500px;
    margin: 10px auto;
  }

  /* Gallery's First slide top block */
  .slide-one-top-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .slide-one-bottom-block{
    width: 100%;
  }

  /* Slide images */
  .slide-img{
    filter: brightness(95%);
  }

  /* Slide Description */
  .slide-desc{
    font-weight: 600;
    font-family: monospace;
    height: 80px;
  }

  /* First Slide A1 & A2 img */
  .slide-a1, .slide-a2{
    width: 49.5%;
    height: 195px;
    object-fit: cover;
  }

  /* First Slide A3 img */
  .slide-a3{
    width: 100%;
    height: 195px;
    object-fit: cover;
    object-position: 100% 55%;
    margin: 8px 0 !important;
  }

  /* Gallery's Second slide */
  .gallery-slide-two{
    width: 95%;
  }

  /* Slide two image container */
  .gallery-slide-img-container{
    height: 400px;
    width: 100%;
  }

  /* Second slide img */
  .slide-b1{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Gallery's Third slide */
  .gallery-slide-three{
    width: 95%;
  }

  /* Second slide img */
  .slide-c1, .slide-c2{
    width: 49.5%;
    height: 100%;
    object-fit: cover;
  }
  /* End of Gallery */

  /* Courses Slides */
  .course-slide{
    border: 1px solid #004470;
    border-radius: 50px;
    padding: 20px 100px;
    margin: 10px auto;
    width: 80%;
    height: 480px;
  }

  /* Title of Courses */
  .course-title{
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 4px;
    color: #262338;
    margin: 10px 0 10px 20px;
    text-align: center;
  }

  /* Course list And More... style */
  .courses-and-more{
    color: #004470;
  }

  /* Container of Course list */
  .course-list-container{
    display: flex;
    justify-content: space-evenly;
  }

  /* Partition of Course list */
  .course-list-partition{
    width: 300px;
    margin: 10px 30px;
    border-left: 4px solid #6ca6cd;
    padding-left: 30px;
    height: fit-content;
  }

  /* Start of Testimonial */
  /* Testimonial Container */
  .testimonial-slide{
    margin: 10px auto;
    width: 80%;
    padding: 20px;
    border: 5px solid #004470;
    border-radius: 12px;
    background-color: #e4f2fd;
    min-height: 350px;
  }


  /* Testimonial avatar container */
  .testimonial-dp-container{
    width: fit-content;
  }


  /* Testimonial bottom container */
  .testimonial-bottom-container{
    display: flex;
    align-items: center;
  }

  .testimonial-star{
    font-size: 24px;
    margin: 10px;
  }

  /* Testimonial DP */
  .testimonial-dp{
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 30px;
  }

  /* Testimonial Authors social icons */
  .testimonial-social-icons{
    font-size: 22px;
    margin: 10px;
  }

  /* Testimonial Description */
  .testimonial-desc{
    text-align: justify;
    height: 250px;
  }

  .testimonial-author{
    color: #202020;
  }

  .testimonial-designation{
    text-align: end;
  }
  /* End of Testimonial */


  /* Start of Footer */

  footer{
    color: #e0e0e0;
  }

  /* Footer top part */
  .footer-top{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .quick-link-container{
    margin: 20px;
  }

  /* Quick links */
  .quick-link{
    text-decoration: none;
    color: #a9ddff;
    display: block;
    padding: 0 16px;
    font-size: 14px;
  }
  .quick-link:hover{
    cursor: pointer;
    background-color: #002842;
    border-radius: 8px;
  }
  /* End of Footer */

  /* Start of Backtop top Container */
  .Backtotop-container{
    margin-bottom: 15px;
    margin-right: 15px;
  }
  /* End of Backtop top Container */

}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}



.spa-container{
  position: relative;
}

.Backtotop-container{
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  height: 50px !important;
  width: 50px !important;
  z-index: 1000;
  animation: btp-animation 3000ms infinite;
}
@keyframes btp-animation {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}
.Backtotop-container:hover{
  opacity: 1;
  cursor: pointer;
  transform: scale(110%);
  animation: none;
}

.backtotop{
  height: 100% !important;
  width: 100% !important;
}